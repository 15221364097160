.scrollbar {
  scrollbar-color: #e2000b #e2000b10;
}

.scrollbar::-webkit-scrollbar {
  width: 4px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: #e2000b;
  border-radius: 2px;
}

.scrollbar::-webkit-scrollbar-track {
  background: #e2000b60;
  border-radius: 2px;
}