$timing: 0.15s;
$timing2x: 0.3s;
$delay: 0.12s;
.menu {
    display: inline-block;
    margin: 0;
    padding: 5px;
    overflow: visible;
    transition-property: opacity, filter;
    transition-duration: $timing;
    transition-timing-function: linear;
    border: 0;
    background-color: transparent;
    color: inherit;
    font-size: 0;
    text-transform: none;
    cursor: pointer;
    // transition: all;
    // transition: $timing;
    .menu_inner {
        transition-duration: $timing;
        transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        &::before {
            transition: top $timing $delay ease, opacity $timing ease;
        }
        &::after {
            transition: bottom $timing $delay ease, transform $timing cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }
    }
    &.active {
        .menu_inner {
            transform: rotate(45deg);
            transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            transition-delay: $delay;
            &::before {
                top: 0;
                transition: top $timing ease, opacity $timing $delay ease;
                opacity: 0;
            }
            &::after {
                bottom: 0;
                transform: rotate(-90deg);
                transition: bottom $timing ease, transform $timing $delay cubic-bezier(0.215, 0.61, 0.355, 1);
            }
        }
    }
}

.menu_block {
    display: inline-block;
    position: relative;
    width: 28px;
    height: 20px;
}

.menu_inner {
    display: block;
    top: 50%;
    transform: translateY(-50%);
    &,
    &::before,
    &::after {
        position: absolute;
        width: 28px;
        height: 3px;
        transition-property: transform;
        transition-duration: $timing2x;
        transition-timing-function: ease;
        background-color: #000;
        border-radius: 1.5px;
    }
    &::before,
    &::after {
        content: "";
        display: block;
    }
    &::before {
        top: (6px + 3px) * -1;
    }
    &::after {
        bottom: (6px + 3px) * -1;
    }
}